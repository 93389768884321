import { isForm, mapFormToObject } from '@/helpers/dom';
import { ClientTemplate, Component } from '@/components/ClientTemplate';
import { Pages, UseResetPasswordCodePageTemplate, PasswordRequestPayload } from '@/types';

@Component
export default class ClientResetPassword extends ClientTemplate<Pages.UseResetPasswordCode> {
  passwordIsHidden = true;

  successfullySet = false;

  get code() {
    return this.store.getResetPasswordCode();
  }

  get lang() {
    return this.i18n.getLang();
  }

  async resetPassword(element: Element) {
    this.error = null;
    if (isForm(element)) {
      const payload = mapFormToObject<PasswordRequestPayload>(element);
      const result = await this.pds.useResetPasswordCode({
        code: this.code,
        password: payload.password,
        lang: this.lang,
      });
      if (result.isFailure()) {
        this.error = result.error;
      } else {
        this.successfullySet = true;
      }
    }
  }

  build() {
    const data: UseResetPasswordCodePageTemplate = {
      successfullySet: this.successfullySet,
      resetPasswordLink: this.actions.create((element) => {
        this.resetPassword(element);
      }),
      passwordIsHidden: this.passwordIsHidden,
      showPasswordLink: this.actions.create(() => {
        this.passwordIsHidden = !this.passwordIsHidden;
      }),
    };
    return [Pages.UseResetPasswordCode, data] as const;
  }
}
